import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      /** @returns {Object} - protection plans data */
      protectionData: (state) => state.protectionData,
    }),
    minProtectionPrice() {
      const prices = [];
      (this.protectionItems || []).forEach((i) => {
        (i.plans || []).forEach((plan) => {
          prices.push(plan.price);
        });
      });

      if (prices.length > 0) {
        return Math.min(...prices);
      }

      return 0;
    },
  },
  methods: {
    getItemPlans(i) {
      const tiers = this.protectionData?.priceTierMatchTable || [];
      const plans = this.protectionData?.plans || [];
      const freeProtectionIds = i?.product?.freeProtectionIds || [];
      const { price } = i?.product || {};
      const tier = tiers.find((t) => t.from <= price && t.to >= price) || {};
      return plans.map((plan) => {
        const currentPlan = plan.tiers.find((t) => t.tier === tier.tier) || {};
        const years = parseInt(plan.name.match(/\d+/)?.[0] || 0, 10);
        const isFree = freeProtectionIds.map(Number).includes(years);
        return {
          id: plan.id,
          name: plan.name,
          shortName: plan.name.substring(0, plan.name.lastIndexOf(' ')),
          price: isFree ? 0 : (currentPlan.price || 0),
          strikePrice: currentPlan.price || 0,
          isFree,
        };
      });
    },
  },
};
