
/**
 * The main layout
 * @category [Layouts]
 * @nuxt-listeners [
 *   'change-global-loading', 'change-global-scrolling', 'change-global-overlay'
 * ]
 */
import { GET_INITIAL_CHECKOUT_DATA, GET_INITIAL_DATA, GET_META_DATA } from '@/api/apolloCommonQueries';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'defaultLayout',
  mixins: [
    headerMenuOverlayUtility,
    respUtility,
  ],
  components: {
    TheLoading: () => import('~/components/templates/TheLoading.vue'),
  },
  data() {
    return {
      overlay: false,
      hasOverlayWithDelay: false,
      loadingPopup: false,
      pageScroll: true,
      redirect: null,
      isMinicartOpen: false,
      isSSR: true,
    };
  },
  head() {
    return {
      title: this.headData?.title || '1StopBedrooms',
      script: [
        {
          // eslint-disable-next-line max-len,vue/max-len
          src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.googleConversion.googleTagManagerGtagAwConversionId}`,
          skip: !this.$config.googleConversion.googleTagManagerGTagGconversionEnable || this.$isSpider,
          async: true,
          body: true,
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
            if (typeof gtag !== 'function'){
              function gtag(){dataLayer.push(arguments);}
            }
            gtag('js', new Date());
            gtag('config', '${this.$config.googleConversion.googleTagManagerGtagAwConversionId}');`,
          skip: !this.$config.googleConversion.googleTagManagerGTagGconversionEnable || this.$isSpider,
          body: true,
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this.$config.googleAnalytics.googleAnalyticsFourAccount}',{'send_page_view': false});`,
          skip: !this.$config.googleAnalytics.googleAnalyticsFourActive || this.$isSpider,
          body: true,
        },
        {
          type: 'text/javascript',
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.$config.googleTagManager.googleTagManagerAccount}');`,
          skip: !this.$config.googleTagManager.googleTagManagerActive || this.$isSpider,
          body: true,
        },
        {
          src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.googleAnalytics.googleAnalyticsFourAccount}`,
          skip: !this.$config.googleAnalytics.googleAnalyticsFourActive || this.$isSpider,
          async: true,
          body: true,
        },
        {
          hid: 'fb-pixel',
          innerHTML: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${this.$config.metaPixel.pixelId});
            fbq('track', 'PageView');
          `,
          type: 'text/javascript',
          charset: 'utf-8',
          skip: !this.$config.metaPixel?.pixelId || this.$isSpider,
        },
      ],
      noscript: [
        {
          type: 'text/javascript',
          // eslint-disable-next-line max-len,vue/max-len
          innerHTML: ` <iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.googleTagManager.googleTagManagerAccount};?>"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          body: true,
          skip: !this.$config.googleTagManager.googleTagManagerActive || this.$isSpider,
        },
        {
          innerHTML: `
            <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=${this.$config.metaPixel?.pixelId}&ev=PageView&noscript=1"/>
          `,
          body: true,
        },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript'],

      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: this.headData?.robots || 'index, follow',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.headData?.description ? this.headData.description
            : '1Stop Bedrooms is your one stop shop for bedroom furniture. Shop the largest collection of bedroom sets. 5 star rated. Free delivery. Financing available.',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.ogData?.title || '1StopBedrooms',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.ogData?.url || 'https://www.1stopbedrooms.com/',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.ogData?.description ? this.headData.description
            : '1Stop Bedrooms is your one stop shop for bedroom furniture. Shop the largest collection of bedroom sets. 5 star rated. Free delivery. Financing available.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogData?.image || 'https://www.1stopbedrooms.com/media/1stopbedrooms-logo.jpg',
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: this.ogData?.image_alt || '1StopBedrooms',
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.ogData?.url || '',
        },
      ],
    };
  },
  async fetch() {
    this.redirect = null;
    this.$store.commit('product/setIsMobile', this.$device.isMobileOrTablet || this.isTouchDevice);
    if (this.$route.name !== 'onepage') {
      await this.getInitialData();
    } else {
      const parameters = Object.entries(this.$route.query)
        .map(([name, value]) => ({ name, value: Array.isArray(value) ? value : (value || '').split('$,$') }));
      const result = await this.$graphql.default.request(
        GET_INITIAL_CHECKOUT_DATA,
        {
          slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          parameters: parameters.length ? parameters : null,
        },
      );
      this.$store.commit('setMetaData', result.pageMetadata || {});
      this.$store.commit('setHeaderData', result.headerQueries || {});
    }
    this.isSSR = process.server;
    if (!this.isSSR) {
      this.setIntegrationsEvents();
      window.history.scrollRestoration = 'auto';
    }
  },
  computed: {
    messages() {
      return this.$store.state.messages;
    },
    headData() {
      return this.$store.state.metaData?.addons?.head;
    },
    ogData() {
      return this.$store.state.metaData?.addons?.metaOg;
    },
    footerData() {
      return this.$store.state.footerData;
    },
    headerData() {
      if (!this.$store.state.headerData) {
        return null;
      }
      const { navigationBrands, ...headerData } = this.$store.state.headerData;
      return headerData;
    },
    isMobileView() {
      return this.$device.isMobileOrTablet || this.isTouchDevice;
    },
    isDesktopView() {
      return !(this.$device.isMobileOrTablet || this.isTouchDevice);
    },
    isCheckoutPage() {
      return this.$route.name === 'onepage';
    },
  },
  watch: {
    overlay(val) {
      /*
       * The "hasOverlayWithDelay" property changes to false with a delay.
       * This is to remove the "hasOverlay" class with a delay.
       * This prevents clicking on site elements under the <v-overlay>.
       */

      if (val) {
        this.hasOverlayWithDelay = val;
      } else {
        new Promise((res) => {
          setTimeout(res, 500);
        }).then(() => {
          this.hasOverlayWithDelay = val;
        });
      }
    },
    '$route.path': function watchRoute() {
      this.$nextTick(async () => {
        if (this.$route.name !== 'onepage' && !this.footerData) {
          await this.getInitialData();
        } else {
          await this.getMetadata();
        }
        this.$nuxt.$emit('change-global-loading', false);
      });
    },
  },
  created() {
    this.$nuxt.$on('change-global-loading', (status) => {
      this.loadingPopup = status;
    });
    this.$nuxt.$on('change-global-scrolling', (status) => {
      if (this.$device.isMobileOrTablet || this.isTouchDevice) {
        // eslint-disable-next-line nuxt/no-globals-in-created
        if (status) document.documentElement.classList.remove('noScroll');
        // eslint-disable-next-line nuxt/no-globals-in-created
        else document.documentElement.classList.add('noScroll');
      }
    });
    this.$nuxt.$on('change-header-scrolling', (status) => {
      this.pageScroll = status;
    });
    this.$nuxt.$on('change-global-overlay', (status) => {
      this.overlay = status;
    });
    this.$nuxt.$on('minicart-open', (status) => {
      this.isMinicartOpen = status;
    });
  },
  mounted() {
    this.pffpTest();
    if (this.isSSR) {
      window.history.scrollRestoration = 'auto';
      if (window.gtag) {
        this.setIntegrationsEvents();
      } else {
        window.addEventListener('gtm_loaded', this.setIntegrationsEvents, { once: true });
      }
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('change-global-loading');
    this.$nuxt.$off('change-global-scrolling');
    this.$nuxt.$off('change-header-scrolling');
    this.$nuxt.$off('change-global-overlay');
    this.$nuxt.$off('minicart-open');
  },
  methods: {
    setIntegrationsEvents() {
      if (!(this.$config.googleConversion?.googleTagManagerGTagGconversionEnable && window.gtag)) {
        return;
      }
      this.$taskManager.append(() => {
        // Google Conversion "page_view"
        window.gtag('event', 'page_view', {
          page_title: this.headData?.title || '1StopBedrooms',
          google_business_vertical: 'retail',
        });
      });
    },
    onClose(msg) {
      if (msg && msg.id) {
        this.$store.commit('removeMessage', msg.id);
      }
    },
    async getMetadata() {
      if (this.$store.state.pageData.globalType === 'CMS' && this.$route.name !== 'couponCodePage') {
        return;
      }
      this.redirect = null;
      const parameters = Object.entries(this.$route.query)
        .map(([name, value]) => ({ name, value: Array.isArray(value) ? value : (value || '').split('$,$') }));
      const result = await this.$graphql.default.request(
        GET_META_DATA,
        {
          slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          parameters: parameters.length ? parameters : null,
        },
      );
      this.isSSR = process.server;
      if (!this.isSSR) {
        this.setIntegrationsEvents();
      }
      this.$store.commit('setMetaData', result.pageMetadata || {});
    },
    async getInitialData() {
      if (this.$store.state.headerData) {
        await this.getMetadata();
        return;
      }
      const parameters = Object.entries(this.$route.query)
        .map(([name, value]) => ({ name, value: Array.isArray(value) ? value : (value || '').split('$,$') }));
      const result = await this.$graphql.default.request(
        GET_INITIAL_DATA,
        {
          slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          parameters: parameters.length ? parameters : null,
        },
      );
      this.$store.commit('setBrandsData', result.headerQueries?.navigationBrands || {});
      this.$store.commit('setMetaData', result.pageMetadata || {});
      this.$store.commit('setHeaderData', result.headerQueries || {});
      this.$store.commit('setFooterData', result.footerQueries || {});
    },
    pffpTest() {
      /**
       * Requests the browser to call a specified function
       * to run the code before the next repaint.
       */
      this.$taskManager.append(() => {
        /*
        * Detection of the uncanny relationship between
        * position fixed and filter property.
        */
        if (process.client) {
          const root = document.querySelector('html');
          const head = document.querySelector('head');
          const body = document.querySelector('body');
          const style = document.createElement('style');
          const css = `
            /* Detection of the uncanny relationship between position fixed and filter property. */
            #t-pffp,
            #t-pffp div {
              position: fixed;
              top: 10px;
              left: 0px;
              filter: blur(0px);
              height: 1px;
              width: 1px;
              opacity: 0;
              pointer-events: none;
            }
          `;
          const html = '<div id="t-pffp"><div></div></div>';
          const template = document.createElement('template');
          template.innerHTML = html;
          const container = template.content.querySelector('#t-pffp');
          const child = container.querySelector('div');

          style.appendChild(document.createTextNode(css.replaceAll(/[ ]+/g, ' ')));
          head.appendChild(style);
          body.appendChild(template.content);

          /*
          * Ensures that all the DOM changes made
          * in the first callback have been rendered
          */
          this.$taskManager.append(() => {
            const rectOfContainer = container.getBoundingClientRect();
            const rectOfChild = child.getBoundingClientRect();
            const different = parseInt(rectOfContainer.top, 10) - parseInt(rectOfChild.top, 10);
            const variableValue = (different === 0) ? 0 : 1;

            /*
            * The value of the --t-pffp variable equal to 0 means
            * that the child element is at the same distance
            * from the viewport as the container
            */
            root.style.setProperty('--t-pffp', variableValue);
          });
        }
      });
    },
  },
};
