
import respUtility from '@/mixins/respUtility';
/**
 * The brands' catalog (it is used as a brands menu in the mobile header and in the Brands Page)
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <BrandCatalog :brands="brands" />
 */
export default {
  name: 'HeaderMenuBrandsCatalog',
  mixins: [respUtility],
  props: {
    /** @prop {Array} [:brands="indexBrands"] - The index brands menu data - gql [BrandsIndexItemType]! */
    brands: {
      type: Array,
      required: true,
    },
    /** @prop {Array} [:brands="indexBrands"] - The index brands menu data - gql [BrandsIndexItemType]! */
    /** @prop {Boolean} [is-menu] - Menu version doesn't have an index sidebar and brands' logos */
    isMenu: {
      type: Boolean,
      default: false,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      searchList: this.brands,
      brandGroups: [
        ['aico', 'michael amini'],
        ['ashley', 'signature design by ashley'],
      ],
    };
  },
  computed: {
    /** Version without empty letters for the brands' list
     * @return {Array} gql [BrandsIndexItemType]!
     */
    formattedBrandsList() {
      const brandsList = this.searchList.map((item) => {
        item.brands.forEach((brand) => {
          this.brandGroups.forEach((group) => {
            if (group.includes(brand.name.toLowerCase())) {
              // eslint-disable-next-line no-param-reassign
              brand.groupName = group.join(', ');
            }
          });
        });
        return item;
      });

      return brandsList.filter((letter) => letter.brands && letter.brands.length);
    },
  },
  watch: {
    /** Change this.currentIndex according to the current search request */
    search(value) {
      if (!value) {
        this.searchList = this.brands;
      }

      const query = value.toLowerCase();
      this.searchList = this.brands.map((letter) => {
        if (!(letter && letter.brands && letter.brands.length)) {
          return letter;
        }
        return {
          ...letter,
          brands: letter.brands.filter((brand) => {
            if (!(brand && brand.name)) {
              return false;
            }

            return brand.name.toLowerCase().includes(query.replace(/\s\s+/g, ' ').trim())
                || brand.groupName?.toLowerCase().includes(query.replace(/\s\s+/g, ' ').trim());
          }),
        };
      });

      if (!this.isMenu && value) {
        setTimeout(() => {
          const brandsBlock = this.$refs && this.$refs.brandsContent;
          if (brandsBlock) {
            brandsBlock.scrollIntoView(true, { behavior: 'smooth' });
          }
        }, 0);
      }
    },
  },
  methods: {
    /** Clear the search input */
    clearSearch() {
      this.search = '';
    },
    /** Scroll the brands' list to the current letter */
    scrollSubmenu(letter) {
      this.clearSearch();
      if (!letter) {
        return;
      }
      this.$nextTick(() => {
        const letterBlock = this.$refs && this.$refs[`letter-${letter.letter}`]
          ? this.$refs[`letter-${letter.letter}`][0].$el
          : null;
        if (letterBlock) {
          letterBlock.scrollIntoView(true, { behavior: 'smooth' });
          const list = this.$refs && this.$refs.list ? this.$refs.list.$el : null;
          if (list) {
            list.scrollIntoView(false, { behavior: 'smooth' });
          }
        }
      });
    },
  },
};
