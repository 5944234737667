// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app_vTl9V{--hheader-main-part:112px;--hheader-additional-part:0px;--hheader:calc(var(--hheader-main-part) + var(--hheader-additional-part))}@media only screen and (min-width:768px){.app_vTl9V{--hheader-main-part:72px}}@media only screen and (min-width:1264px){.app_vTl9V{--hheader-main-part:88px}.app_vTl9V.isDesktopView_6YYKh{--hheader-additional-part:57px}}.app_vTl9V.isCheckoutPage_hYkea{--hheader-main-part:56px}@media only screen and (min-width:1264px){.app_vTl9V.isCheckoutPage_hYkea{--hheader-main-part:80px}.app_vTl9V.isCheckoutPage_hYkea.isDesktopView_6YYKh{--hheader-additional-part:0}}html.noScroll{overflow:hidden}.v-application.hasOverlay_ZGPf5 .v-main__wrap>:not(.overlay_Cc56x){pointer-events:none}.v-application .v-application--wrap .v-main{min-height:100vh}.v-application .v-main__wrap>div{height:100%}.v-application .v-main__wrap .overlay_Cc56x{pointer-events:none}.v-application.noScroll_5UPFu{overflow:hidden}.v-application.minicart_open_9Soxi{max-height:100vh}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "app_vTl9V",
	"isDesktopView": "isDesktopView_6YYKh",
	"isCheckoutPage": "isCheckoutPage_hYkea",
	"hasOverlay": "hasOverlay_ZGPf5",
	"overlay": "overlay_Cc56x",
	"noScroll": "noScroll_5UPFu",
	"minicart_open": "minicart_open_9Soxi"
};
module.exports = ___CSS_LOADER_EXPORT___;
