
export default {
  name: 'HeaderAdditional',
  computed: {
    links() {
      const isBoutique = this.$store.state.metaData?.addons?.head?.isBoutique;
      return [
        {
          iconName: isBoutique ? 'IconHeaderPromoBoutique-24x24' : 'IconHeaderPromo-24x24',
          title: 'America’s Highest Rated Furniture Store',
          href: 'reviews',
          class: 'mr-auto',
          isInternal: true,
        },
        {
          iconName: isBoutique ? 'IconHeaderClearanceBoutique-24x24' : 'IconHeaderClearance-24x24',
          title: 'Clearance',
          href: 'clearance-deals',
          class: 'link-bold',
          isInternal: true,
        },
        {
          iconName: 'IconHeaderShipping-24x24',
          title: 'Free Shipping',
          href: 'free-shipping',
          class: '',
        },
      ];
    },
  },
};
